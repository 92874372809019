import React from 'react';

import Meta from '../components/meta';

import ExtLink from '../components/extLink';

import { StaticImage } from "gatsby-plugin-image";

import Contents from '../components/contents';

const Access = () =>{



    return(
        <>

            <Meta
                title="アクセス"
                description="本社、東京支店、大阪支店についての情報をご案内します。住所、電話番号、地図、アクセスについて。"
            />

            <Contents>

                <div className="c-sub-header">
                    <em className="c-sub-header--eng u-josef">
                    ACCESS
                    </em>

                    <h1 className="c-sub-header--title">
                    アクセス
                    </h1>
                </div>



                <div className="p-access">
                    <div className="p-access-list">

                        <div className="p-access-list-item">
                            <div className="p-access-box">
                                <div className="p-access-box-image">
                                    <StaticImage
                                        src="../images/kanazawa_office.png"
                                        alt="金沢本社"
                                        placeholder="blurred"
                                        quality="100"
                                    />
                                </div>

                                <div className="p-access-box-cnt">
                                    <p className="p-access-box--title">
                                    金沢本社
                                    </p>

                                    <p className="p-access-box--desc">
                                    〒920-0022<br />
                                    石川県金沢市北安江3丁目6番6号 メッセヤスダ1F/2F
                                    </p>
                                    
                                    <div className="p-access-box-data">
                                        <div className="p-access-box-data__item">
                                            <p className="p-access-box-data__item--title">
                                            TEL
                                            </p>
                                            <p className="p-access-box_data__item--text">
                                            076-255-2012
                                            </p>
                                        </div>

                                        <div className="p-access-box-data__item">
                                            <p className="p-access-box-data__item--title">
                                            営業時間
                                            </p>
                                            <p className="p-access-box_data__item--text">
                                            9:00～18:00
                                            </p>
                                        </div>

                                        <div className="p-access-box-data__item">
                                            <p className="p-access-box-data__item--title">
                                            定休日
                                            </p>
                                            <p className="p-access-box_data__item--text">
                                            土日祝日
                                            </p>
                                        </div>
                                    </div>

                                    <div className="c-link u-align-right">
                                        <ExtLink link="https://goo.gl/maps/Dut7YMJeN9kjsvKg6" title="金沢本社" classTag="c-link--btn is-external" target="true">
                                        地図を確認<span className="c-link--cursor"></span>
                                        </ExtLink>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-access-list-item">
                            <div className="p-access-box">
                                <div className="p-access-box-image">
                                    <StaticImage
                                        src="../images/tokyo_office.png"
                                        alt="東京事務所"
                                        placeholder="blurred"
                                        quality="100"
                                    />
                                </div>

                                <div className="p-access-box-cnt">
                                    <p className="p-access-box--title">
                                    東京事務所
                                    </p>

                                    {/* <p className="p-access-box--desc">
                                    {'＜'}DX事業本部{'＞'}<br />
                                    〒151‐0072<br />
                                    東京都渋谷区幡ヶ谷1-34-14 宝ビル8F
                                    </p>
                                    
                                    <div className="p-access-box-data">
                                        <div className="p-access-box-data__item">
                                            <p className="p-access-box-data__item--title">
                                            TEL
                                            </p>
                                            <p className="p-access-box_data__item--text">
                                            050-3085-2620
                                            </p>
                                        </div>

                                        <div className="p-access-box-data__item">
                                            <p className="p-access-box-data__item--title">
                                            営業時間
                                            </p>
                                            <p className="p-access-box_data__item--text">
                                            9:00～18:00
                                            </p>
                                        </div>

                                        <div className="p-access-box-data__item">
                                            <p className="p-access-box-data__item--title">
                                            定休日
                                            </p>
                                            <p className="p-access-box_data__item--text">
                                            土日祝日
                                            </p>
                                        </div>
                                    </div>

                                    <div className="c-link u-align-right">
                                        <ExtLink link="http://goo.gl/maps/KcZeP" title="東京支社" classTag="c-link--btn is-external" target="true">
                                        地図を確認<span className="c-link--cursor"></span>
                                        </ExtLink>
                                    </div>
                                    
                                    <br /> */}

                                    <p className="p-access-box--desc">
                                    {/* {'＜'}デジタルマーケティング事業本部{'＞'}<br /> */}
                                    〒102-0083<br />
                                    東京都千代田区麹町5-3-23 日テレ四谷ビル
                                    </p>
                                    
                                    <div className="p-access-box-data">

                                        <div className="p-access-box-data__item">
                                            <p className="p-access-box-data__item--title">
                                            TEL
                                            </p>
                                            <p className="p-access-box_data__item--text">
                                            050-3085-2620
                                            </p>
                                        </div>

                                        <div className="p-access-box-data__item">
                                            <p className="p-access-box-data__item--title">
                                            営業時間
                                            </p>
                                            <p className="p-access-box_data__item--text">
                                            9:00～18:00
                                            </p>
                                        </div>

                                        <div className="p-access-box-data__item">
                                            <p className="p-access-box-data__item--title">
                                            定休日
                                            </p>
                                            <p className="p-access-box_data__item--text">
                                            土日祝日
                                            </p>
                                        </div>
                                    </div>

                                    <div className="c-link u-align-right">
                                        <ExtLink link="https://maps.app.goo.gl/kA6Zr5AVu5wCmnJ7A" title="東京事務所" classTag="c-link--btn is-external" target="true">
                                        地図を確認<span className="c-link--cursor"></span>
                                        </ExtLink>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-access-list-item">
                            <div className="p-access-box">
                                <div className="p-access-box-image">
                                    <StaticImage
                                        src="../images/osaka_office.png"
                                        alt="大阪事務所"
                                        placeholder="blurred"
                                        quality="100"
                                    />
                                </div>

                                <div className="p-access-box-cnt">
                                    <p className="p-access-box--title">
                                    大阪事務所
                                    </p>

                                    <p className="p-access-box--desc">
                                    〒530-0017<br />
                                    大阪府大阪市北区角田町8-1 大阪梅田ツインタワーズ・ノース 23F
                                    </p>
                                    
                                    <div className="p-access-box-data">
                                        <div className="p-access-box-data__item">
                                            <p className="p-access-box-data__item--title">
                                            TEL
                                            </p>
                                            <p className="p-access-box_data__item--text">
                                            050-3033-5408
                                            </p>
                                        </div>

                                        <div className="p-access-box-data__item">
                                            <p className="p-access-box-data__item--title">
                                            営業時間
                                            </p>
                                            <p className="p-access-box_data__item--text">
                                            9:00～18:00
                                            </p>
                                        </div>

                                        <div className="p-access-box-data__item">
                                            <p className="p-access-box-data__item--title">
                                            定休日
                                            </p>
                                            <p className="p-access-box_data__item--text">
                                            土日祝日
                                            </p>
                                        </div>
                                    </div>

                                    <div className="c-link u-align-right">
                                        <ExtLink link="https://goo.gl/maps/CMLk62c2xFo" title="大阪事務所" classTag="c-link--btn is-external" target="true">
                                        地図を確認<span className="c-link--cursor"></span>
                                        </ExtLink>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </Contents>

        </>
    )
}

export default Access;


